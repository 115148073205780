<template>
  <div v-if="url" @click="clickHandler">
    <svg-icon class-name="reportbug-icon" :icon-class="iconClass" />
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';

export default {
  components: {
    SvgIcon
  },
  props: {
    url: {
      type: String
    },
    iconClass: {
      type: String,
      default: 'bug'
    }
  },
  methods: {
    clickHandler() {
      window.location.href = this.url;
      return true;
    }
  }
};
</script>

<style scoped>
.reportbug-icon {
  font-size: 20px;
  cursor: pointer;
  vertical-align: -4px !important;
}
</style>
